@if (merchant) {
  <main class="clickable" tabindex="0" (click)="selectMerchant()">
    @if (type === 'column') {
      <div
        class="merchant-card position-relative d-flex flex-column bg-white p-1 sm-radius border">
        <!-- NOTE :: MAKE OR REVOKE FAVORITE SECTION -->
        @if (!disabledFavoriteAction) {
          <section
            class="position-absolute bg-white rounded-circle border z-3 end-0 me-2 mt-2 p-1 d-flex px-2"
            role="button"
            tabindex="2">
            @if (!isLoading) {
              @if (merchant.is_favorite_merchant) {
                <span
                  type="button"
                  (click)="makeFavoriteMerchants(merchant, $event)"
                  tabindex="0"
                  title="Revoke Favorite">
                  <span class="text-primary"
                    ><i class="fa-solid fa-star"></i
                  ></span>
                </span>
              } @else if (!merchant.is_favorite_merchant) {
                <span
                  type="button"
                  (click)="makeFavoriteMerchants(merchant, $event)"
                  tabindex="0"
                  title="Make Favorite">
                  <span class="text-primary"
                    ><i class="fa-regular fa-star"></i
                  ></span>
                </span>
              }
            } @else {
              <span class="text-primary"
                ><i class="fas fa-spinner fa-pulse"></i
              ></span>
            }
          </section>
        }

        <!-- NOTE :: IMAGE SECTION -->
        <section
          class="image-section d-flex justify-content-center align-items-center bg-input sm-radius flex-grow-1">
          @if (merchant.lookup_image) {
            <img
              [src]="merchant.lookup_image"
              alt="{{ merchant.lookup_title }} image"
              class="image object-fit-cover" />
          } @else {
            <span class="d-flex justify-content-center">
              <i class="fa-solid fa-mountain-sun fa-3x my-5 text-secondary"></i>
            </span>
          }
        </section>
        <section class="d-flex flex-column mt-2 pb-2">
          <small
            >{{ merchant.lookup_title }} ({{ merchant.lookup_subtitle }})</small
          >

          <!-- TODO :: TO REMOVE AND SHOW MERCHANT CATEGORY  -->
          <small class="fs-x-small text-secondary mt-1">{{
            merchant.merchant_category_name
          }}</small>
        </section>
      </div>
    }

    @if (type === 'row') {
      <div class="d-flex flex-row mt-2 overflow-hidden rowContainer">
        <div
          class="bg-secondary lg-radius shadow-sm d-flex align-items-center justify-content-center overflow-hidden imgContainer">
          @if (merchant.lookup_image) {
            <img
              [src]="merchant.lookup_image"
              alt="{{ merchant.lookup_title }} image"
              class="image object-fit-cover" />
          } @else {
            <i class="fa-solid fa-mountain-sun fa-2x text-secondary"></i>
          }
        </div>

        <div
          class="ms-3 d-flex flex-column justify-content-around flex-grow-1 py-1">
          <b class="fs-medium">
            {{ merchant.lookup_title }}
          </b>
          <span class="text-secondary">
            {{ merchant.merchant_category_name }}
          </span>
          <small>
            {{ merchant.lookup_subtitle }}
          </small>
        </div>
      </div>
    }
  </main>
}
